import getTimer from "../functions/getTimer"

const Timer = ({players,interval,timer,setTimer})=>{
    return <>
    <h3>
        Timer
    </h3>
    <h4 className="timer">
        {
            players && getTimer(players, interval, timer, setTimer)
        }
    </h4>
    </>
}

export default Timer