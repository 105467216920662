import { TextField } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./CreateRoom.css"
import { useState } from "react";
import Stack from "@mui/joy/Stack";
import { LoadingButton } from "@mui/lab";
import { createRoom } from "../../actions/room";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from "react-router";
import Footer from "../Footer/Footer";

const CreateRoom = ()=>{
    const USER_NAME = "userName";
    const CREATE_ROOM = "createRoom"
    const [error,setError] = useState(false)
    const [helperText,setHelperText] = useState("")

    const [userName,setUserName] = useState("")
    const room = useSelector(state=>state.room)
    const loading = room.loading;

    const dispatch = useDispatch()
    if(room.status)
    {
        return <Navigate to="/lobby" />
    }
    const inputHandler = (e)=>{
        switch(e.target.name)
        {
            case USER_NAME:
                setUserName(e.target.value);
                setError(false);
                setHelperText("")
            break;
            case CREATE_ROOM:
                if(userName==="")
                {
                    setError(true)
                    setHelperText("Username is Required")
                }
                else
                {
                    dispatch(createRoom(userName))
                }
            break;
            default:

        }
    }

    return <div className="bg-container">
        <div className="background container text-center">
            <Stack gap={4} alignItems="center" className="mb-5 pt-2"  >
            <img src="/Assets/Images/welcome.png" className="img-fluid w-50" alt="harmonichurdles" />
                <img src="/Assets/Images/logo1.png" className="img-fluid w-75" alt="harmonichurdles" />
                <img src="/Assets/Images/logo2.png" className="img-fluid w-25" alt="harmonichurdles" />
            </Stack>
            <div className="d-flex justify-content-center align-items-center flex-column">
                <img src="/Assets/Images/profile.png" className="img-fluid profileLogo mb-0" alt="harmonichurdles" />
                <TextField variant="filled" color="secondary" label="Enter Your Name" className="mb-4" value={userName} name={USER_NAME} error={error} helperText={helperText} onChange={inputHandler} required />
            </div>
            <div>
                <LoadingButton variant="contained" size="large" loading={loading} loadingPosition="end" name={CREATE_ROOM} onClick={inputHandler} color="secondary" endIcon={<ArrowForwardIcon />}>
                    Create Room
                </LoadingButton>
            </div>
        </div>
        <Footer />
    </div>
}

export default CreateRoom