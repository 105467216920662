import {initializeApp} from "firebase/app"
import {getFirestore,collection} from "firebase/firestore"
const firebaseConfig = {
    apiKey: "AIzaSyCr28rhb2WEkWJ84iWCuZmT6SuTYVGMScY",
    authDomain: "harmonic-hurdles.firebaseapp.com",
    projectId: "harmonic-hurdles",
    storageBucket: "harmonic-hurdles.appspot.com",
    messagingSenderId: "94391599660",
    appId: "1:94391599660:web:409679b3e670a52e2ac789"
  };

initializeApp(firebaseConfig)


const db = getFirestore()

const collectionRef = collection(db,"rooms");

export {db,collectionRef};