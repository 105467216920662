import { doc, getDoc, updateDoc } from "firebase/firestore"
import { ANSWER_SUCCESS_FAIL, ANSWER_SUCCESS_REQUEST, ANSWER_SUCCESS_SUCCESS, CHAT_UPDATE_FAIL, CHAT_UPDATE_REQUEST, CHAT_UPDATE_SUCCESS, GAME_BEGIN_FAIL, GAME_BEGIN_REQUEST, GAME_BEGIN_SUCCESS, JOIN_ROOM_FAIL, JOIN_ROOM_REQUEST, JOIN_ROOM_SUCCESS, ROOM_CREATE_FAIL, ROOM_CREATE_REQUEST, ROOM_CREATE_SUCCESS, SONG_SELECT_FAIL, SONG_SELECT_REQUEST, SONG_SELECT_SUCCESS, UPDATE_SHUFFLE_FAIL, UPDATE_SHUFFLE_REQUEST, UPDATE_SHUFFLE_SUCCESS } from "../constants/roomConstants"
import { db } from "../Firebase/config"
import { songs } from "../functions/songs"
// import servernow from "servernow"
// import identifyOs from "../functions/identifyOs"
import axios from "axios"
import { endpoint } from "../functions/endpoints"

export const createRoom = (name)=>{
    return async (dispatch)=>{
        dispatch({type:ROOM_CREATE_REQUEST})
        try
        {
            const data = await axios.post(endpoint+"/api/hh/room/create",{name})
            if(data.data.status)
            {
                dispatch({type:ROOM_CREATE_SUCCESS,payload:{playerId:data.data.message.playerId,roomId:data.data.message.roomId}})
            }
            else
            {
                dispatch({type:ROOM_CREATE_FAIL})
            }
        }
        catch(e)
        {
            console.log(e)
            dispatch({type:ROOM_CREATE_FAIL})
        }
    }
}

export const joinRoom = (name,id)=>{
    return async (dispatch)=>{
        dispatch({type:JOIN_ROOM_REQUEST})
        try
        {
            const data = await axios.post(endpoint+"/api/hh/room/join",{name,id}) 
            if(data.data.status)
            {
                dispatch({type:JOIN_ROOM_SUCCESS,payload:{playerId:data.data.message.playerId,roomId:data.data.message.roomId}})
            }
            else
            {
                dispatch({type:JOIN_ROOM_FAIL})
            }
        }
        catch(e)
        {
            console.log(e)
            dispatch({type:JOIN_ROOM_FAIL})
        }
    }
}

export const gameBegin = (id)=>{
    return async (dispatch)=>{
        dispatch({type:GAME_BEGIN_REQUEST})
        try
        {
            const data = await axios.post(endpoint+"/api/hh/game/begin",{id}) 
            if(data.data.status)
            {
                dispatch({type:GAME_BEGIN_SUCCESS})
            }
            else
            {
                dispatch({type:GAME_BEGIN_FAIL})
            }
        }
        catch(e)
        {
            dispatch({type:GAME_BEGIN_FAIL})
            console.log(e)
        }
    }
}

// export const changeTurn = (players,turn,id)=>{
//     return async (dispatch)=>{
//         dispatch({type:CHANGE_TURN_REQUEST})
//         // const playerId = name+"-"+Number.parseInt(Math.random()*100000);
//         try
//         {
//             const docRef = doc(db,"rooms",id)
//             const data = await getDoc(docRef)
//             servernow().then(async (time) => {
//                 await updateDoc(docRef,{
//                     ...data.data(),
//                     players,
//                     songSelected:"",
//                     stage:0,
//                     turn,
//                     end:turn===0 && data.data().level>=5?true:false,
//                     level:turn===0 ? data.data().level+1:data.data().level,
//                     turnStartedAt:time + (identifyOs(navigator.userAgent)*1000),
//                     position:1,
//                     startedAt : ((time-time%1000)/1000) + identifyOs(navigator.userAgent),
//                     shuffle:[]
//                 })
//             })
//             dispatch({type:CHANGE_TURN_SUCCESS})
//         }
//         catch(e)
//         {
//             dispatch({type:CHANGE_TURN_FAIL})
//             console.log(e)
//         }
//     }
// }

export const songSelect = (songSelected,id)=>{
    return async (dispatch)=>{
        dispatch({type:SONG_SELECT_REQUEST})
        // const playerId = name+"-"+Number.parseInt(Math.random()*100000);
        try
        {
            await axios.post(endpoint+"/api/hh/game/songSelect",{songSelected,id})   
            dispatch({type:SONG_SELECT_SUCCESS})
        }
        catch(e)
        {
            dispatch({type:SONG_SELECT_FAIL})
            console.log(e)
        }
    }
}

export const updateShuffle = (shuffle,id)=>{
    return async (dispatch)=>{
        dispatch({type:UPDATE_SHUFFLE_REQUEST})
        // const playerId = name+"-"+Number.parseInt(Math.random()*100000);
        try
        {
            const docRef = doc(db,"rooms",id)
            const data = await getDoc(docRef)
            await updateDoc(docRef,{
                ...data.data(),
                shuffle
            })
                dispatch({type:UPDATE_SHUFFLE_SUCCESS})
        }
        catch(e)
        {
            dispatch({type:UPDATE_SHUFFLE_FAIL})
            console.log(e)
        }
    }
}

// export const hold = (players,id)=>{
//     return async (dispatch)=>{
//         dispatch({type:HOLD_REQUEST})
//         // const playerId = name+"-"+Number.parseInt(Math.random()*100000);
//         try
//         {
//             const docRef = doc(db,"rooms",id)
//             const data = await getDoc(docRef)
//             servernow().then(async (time) => {
//                 await updateDoc(docRef,{
//                     ...data.data(),
//                     players,
//                     stage:2,
//                     startedAt : ((time-time%1000)/1000) + identifyOs(navigator.userAgent),
//                     shuffle:[]
//                 })
//             })
            
//                 dispatch({type:HOLD_SUCCESS})
//         }
//         catch(e)
//         {
//             dispatch({type:HOLD_FAIL})
//             console.log(e)
//         }
//     }
// }

export const answerSuccess = (players,id)=>{
    return async (dispatch)=>{
        dispatch({type:ANSWER_SUCCESS_REQUEST})
        // const playerId = name+"-"+Number.parseInt(Math.random()*100000);
        try
        {
            await axios.post(endpoint+"/api/hh/game/answerSuccess",{players,id})
            dispatch({type:ANSWER_SUCCESS_SUCCESS})
        }
        catch(e)
        {
            dispatch({type:ANSWER_SUCCESS_FAIL})
            console.log(e)
        }
    }
}

export const chatUpdate = (chat,room,players,guess,id)=>{
    return async (dispatch)=>{
        dispatch({type:CHAT_UPDATE_REQUEST})
        // const playerId = name+"-"+Number.parseInt(Math.random()*100000);
        try
        {
            await axios.post(endpoint+"/api/hh/game/chatUpdate",{chat,id})
            dispatch({type:CHAT_UPDATE_SUCCESS})
            const time = (await axios.post(endpoint+"/api/hh/time")).data.curTime;
            if (!players.players[room.playerId].turn &&!players.players[room.playerId].answer && players.songSelected &&((players.level%2===1 && songs[players.songSelected].name.toUpperCase() === guess.toUpperCase()) || (players.level%2===0 && songs[players.songSelected].singer.toUpperCase() === guess.toUpperCase()))) {
                let temp = players.players;
                temp[room.playerId].answer = true
                temp[room.playerId].position = players.position
                temp[room.playerId].answeredAt = time
                dispatch(answerSuccess(temp,room.roomId))
                }
        }
        catch(e)
        {
            dispatch({type:CHAT_UPDATE_FAIL})
            console.log(e)
        }
    }
}