const getTimer =(players,interval,timer,setTimer)=>{
    if(players.stage===0)
    {
        if((10-timer%12)===0)
        {
            clearInterval(interval);
            setTimer(0)
            return "00:00";
        }
        else
        {
            return `00:${10-timer%12}`;
        }
    }
    else if(players.stage===1)
    {
        if((30-timer%32)===0)
        {
            clearInterval(interval);
            setTimer(0)
            return "00:00";
        }
        else
        {
            return `00:${30-timer%32}`;
        }
    }
    else
    {
        if((5-timer%6)===0)
        {
            clearInterval(interval);
            setTimer(0)
            return "00:00";
        }
        else
        {
            return `00:${5-timer%6}`;
        }
    }
}

export default getTimer;