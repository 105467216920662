import { Stack } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";
import "./Game.css"
import hashSong from "../../functions/hashSong";
import { length, songs } from "../../functions/songs";
import getSnapshot from "../../functions/getSnapshot";
import QuestionGuess from "../../components/QuestionGuess";
import Timer from "../../components/Timer";
import Turn from "../../components/Turn";
import { PlayersBig, PlayersSmall } from "../../components/Players";
import ScoreBoard from "../../components/ScoreBoard";
import manipulateTime from "../../functions/manipulateTime";
import { chatUpdate, songSelect } from "../../actions/room";
import Chat from "../../components/Chat";
import { Navigate } from "react-router";
import axios from "axios";
import { endpoint } from "../../functions/endpoints";
import Footer from "../Footer/Footer";
// import servernow from "servernow";
const Game = () => {
    const room = useSelector(state => state.room)
    const [players, setPlayers] = useState(undefined)
    const [turn, setTurn] = useState(undefined)
    const [timer, setTimer] = useState(1)
    const [randomChoice, setRandomChoice] = useState(new Set([Math.floor(Math.random() * length) % length, Math.floor(Math.random() * length) % length, Math.floor(Math.random() * length) % length]));
    const [guess, setGuess] = useState("")
    const [isChoiceSelected, setIsChoiceSelected] = useState(false)
    const [interval, updateInterval] = useState(0)
    const [scoreBoardModalOpen, setScoreBoardModalOpen] = useState(false)
    const [backgroundMusic,setBackgroundMusic] = useState()
    const [songSelected, setSongSelected] = useState(players ? players.songSelected : "");
    const [copyFeedback, setCopyFeedback] = useState("Copy");
    const [chat,setChat] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        getSnapshot(room,setTurn,setTimer,setChat,players,setIsChoiceSelected,setGuess,setRandomChoice,setPlayers,setSongSelected,updateInterval,setScoreBoardModalOpen)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(()=>{
        manipulateTime(players,setScoreBoardModalOpen,setBackgroundMusic)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[timer])

    const inputHandler = async (e) => {
        switch (e.target.name) {
            case "choiceBtn":
                setIsChoiceSelected(true);
                setSongSelected(e.target.getAttribute("song"))
                dispatch(songSelect(e.target.getAttribute("song"),room.roomId))
                break;
            case "guess":
                setGuess(e.target.value)
                break;
            case "submitBtn":
                setGuess("");
                if(guess.trim()!=="")
                {
                    const time = (await axios.post(endpoint+"/api/hh/time")).data.curTime
                    dispatch(chatUpdate([...chat,{name:room.playerId,message:guess,messagedAt:time}],room,players,guess,room.roomId))
                }
                break;
            default:

        }
    }
    return players && players.end?
    <Navigate to={`/winners/${room.roomId}`} />:
    <div className="bg-container d-flex">
        <ScoreBoard players={players} scoreBoardModalOpen={scoreBoardModalOpen} setScoreBoardModalOpen={setScoreBoardModalOpen} />
        {/* Big Screen */}
        <div className="col-md-2 d-md-block d-sm-none d-none d-flex flex-column justify-content-center align-items-center border-end border-secondary my-5">
            <PlayersBig copyFeedback={copyFeedback} setCopyFeedback={setCopyFeedback} players={players} />
        </div>
        <div className="background d col-md-10 col-sm-12 col-12 flex-wrap justify-content-center text-center">
            <div className="col-12">
                <Stack gap={4} alignItems="center" className="pt-5 pb-2 col-md-5 col-sm-7 col-7 mx-auto"  >
                    <img src="/Assets/Images/logo1.png" className="img-fluid col-md-10 col-sm-12 col-12" alt="harmonichurdles" />
                    <img src="/Assets/Images/logo2.png" className="img-fluid col-md-4 col-sm-5 col-5" alt="harmonichurdles" />
                </Stack>
            </div>
            <div className="d-flex col-12 pb-5">
                <div className="col-12">
                    {/* Small Screen */}
                    <div className="d-md-none d-sm-block d-block d-flex col-sm-12 col-12 flex-column justify-content-center align-items-center border-end border-secondary">
                        <PlayersSmall copyFeedback={copyFeedback} setCopyFeedback={setCopyFeedback} players={players} />
                    </div>
                        <div className="d-flex col-12 flex-wrap justify-content-start border-bottom border-top py-2">
                            <div className="col-sm-6 col-6 border-sm-bottom col-md-4 d-flex flex-row border-end justify-content-center align-items-center PlayerTurn">
                                <Turn players={players} />
                            </div>
                            <div className="col-sm-6 col-6 mt-2 col-md-6">
                                <Timer interval={interval} players={players} setTimer={setTimer} timer={timer} />
                            </div>
                        </div>
                        {
                            players && <QuestionGuess hashSong={hashSong} inputHandler={inputHandler} isChoiceSelected={isChoiceSelected} players={players} randomChoice={randomChoice} room={room} songSelected={songSelected} songs={songs} turn={turn} backgroundMusic={backgroundMusic} />
                        }
                        <Chat chat={chat} setGuess={setGuess} setChat={setChat} guess={guess} players={players} inputHandler={inputHandler} />
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default Game