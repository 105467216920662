import { LoadingButton } from "@mui/lab"
import { Avatar, Box, Modal, Typography } from "@mui/material"
import { stringAvatar } from "../functions/avatar"
import getPoints from "../functions/getPoints"

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'text.secondary',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ScoreBoard = ({scoreBoardModalOpen,setScoreBoardModalOpen,players})=>{
    return <Modal open={scoreBoardModalOpen} onClose={() => setScoreBoardModalOpen(false)}>
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" sx={{ color: "secondary.main" }} className="text-center" variant="h4" component="h6">
                    Score Board
                </Typography>
                {
                    players && <Typography id="modal-modal-title" sx={{ color: "secondary.main" }} className="text-center" variant="h4" component="h6">
                        Level {players.level}
                    </Typography>
                }
                {
                    players ? Object.keys(players.players).map((value, key) => <div key={key} className="d-flex flex-column justify-content-between align-items-center">
                        <div className="d-flex col-12 justify-content-center align-items-center my-2">
                            <div className="d-flex justify-content-center align-items-center col-4 gap-3">
                                <Avatar {...stringAvatar(players.players[value].name)} className="col-8" />
                                <Typography fontSize={20} color="white" className="col-4">{players.players[value].name}</Typography>
                            </div>
                            <Typography fontSize={20} color={players.players[value].answer ? "success.main" : "white"} className="col-3 text-end fw-bold">+ {getPoints(players.players[value].position,players.players[value].answeredAt-players.turnStartedAt,players.level)}</Typography>
                        </div>
                    </div>) : <LoadingButton loading style={{ fontSize: "2em" }} />
                }
            </Box>
        </Modal>
}

export default ScoreBoard;