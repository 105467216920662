export const ROOM_CREATE_REQUEST = "ROOM_CREATE_REQUEST";
export const ROOM_CREATE_SUCCESS = "ROOM_CREATE_SUCCESS";
export const ROOM_CREATE_FAIL = "ROOM_CREATE_FAIL";

export const JOIN_ROOM_REQUEST = "JOIN_ROOM_REQUEST";
export const JOIN_ROOM_SUCCESS = "JOIN_ROOM_SUCCESS";
export const JOIN_ROOM_FAIL = "JOIN_ROOM_FAIL";

export const GAME_BEGIN_REQUEST = "GAME_BEGIN_REQUEST";
export const GAME_BEGIN_SUCCESS = "GAME_BEGIN_SUCCESS";
export const GAME_BEGIN_FAIL = "GAME_BEGIN_FAIL";

export const CHANGE_TURN_REQUEST = "CHANGE_TURN_REQUEST";
export const CHANGE_TURN_SUCCESS = "CHANGE_TURN_SUCCESS";
export const CHANGE_TURN_FAIL = "CHANGE_TURN_FAIL";

export const SONG_SELECT_REQUEST = "SONG_SELECT_REQUEST";
export const SONG_SELECT_SUCCESS = "SONG_SELECT_SUCCESS";
export const SONG_SELECT_FAIL = "SONG_SELECT_FAIL";

export const UPDATE_SHUFFLE_REQUEST = "UPDATE_SHUFFLE_REQUEST";
export const UPDATE_SHUFFLE_SUCCESS = "UPDATE_SHUFFLE_SUCCESS";
export const UPDATE_SHUFFLE_FAIL = "UPDATE_SHUFFLE_FAIL";

export const HOLD_REQUEST = "HOLD_REQUEST";
export const HOLD_SUCCESS = "HOLD_SUCCESS";
export const HOLD_FAIL = "HOLD_FAIL";

export const ANSWER_SUCCESS_REQUEST = "ANSWER_SUCCESS_REQUEST";
export const ANSWER_SUCCESS_SUCCESS = "ANSWER_SUCCESS_SUCCESS";
export const ANSWER_SUCCESS_FAIL = "ANSWER_SUCCESS_FAIL";

export const CHAT_UPDATE_REQUEST = "CHAT_UPDATE_REQUEST";
export const CHAT_UPDATE_SUCCESS = "CHAT_UPDATE_SUCCESS";
export const CHAT_UPDATE_FAIL = "CHAT_UPDATE_FAIL";