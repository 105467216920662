import { Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./Home.css"
import Stack from '@mui/joy/Stack';
import LockIcon from '@mui/icons-material/Lock';
import { LoadingButton } from '@mui/lab';
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { ToastContainer } from "react-toastify";

const Home = ()=>{
    const createRoomRef = useRef()
    const [loading,setLoading] = useState(false)

    const inputHandler = (e)=>{
        switch(e.target.name)
        {
            case "createRoom":
                setLoading(true)
            break;
            default:

        }
    }

    return <div className="bg-container">
        <div className="background container text-center py-5">
            <Stack gap={4} alignItems="center" className="mb-5 mt-2"  >
                <img src="/Assets/Images/welcome.png" className="img-fluid w-50" alt="harmonichurdles" />
                <img src="/Assets/Images/logo1.png" className="img-fluid w-75" alt="harmonichurdles" />
                <img src="/Assets/Images/logo2.png" className="img-fluid w-25" alt="harmonichurdles" />
            </Stack>
            <Stack direction="column" alignItems="center" gap={2}>
                <LoadingButton variant="contained" name="createRoom" LinkComponent={Link} to="/createroom" className="text-light" onClick={inputHandler} loading={loading} loadingPosition="end" ref={createRoomRef} size="large" color="secondary" endIcon={<LockIcon />}>
                    Create A Room
                </LoadingButton>
                <Button variant="contained" LinkComponent={Link} className="text-light" to="/join" size="large" color="secondary" endIcon={<ArrowForwardIcon />}>
                    Join A Room
                </Button>
            </Stack>
        </div>
        <Footer />
        <ToastContainer />
    </div>
}

export default Home