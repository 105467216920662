import { Avatar, Button, Tooltip, Typography } from "@mui/material";
import getPlayersSorted from "../functions/getPlayersSorted";
import { stringAvatar } from "../functions/avatar";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import "./Players.css"

export const PlayersBig = ({copyFeedback,setCopyFeedback,players})=>{
    const room = useSelector(state => state.room)
    return <>
    <div className="mb-5 text-center d-sm-none d-none d-md-block">
        <Typography>Room ID</Typography>
        <Tooltip title={copyFeedback} arrow placement="right-start">
            <Button variant="outlined" className="w-auto text-wrap roomIdBtn"  color="secondary" name="copy" endIcon={<ContentCopyIcon />} onMouseDown={() => { copy(room.roomId); setCopyFeedback("Copied !!") }} onMouseLeave={() => { setCopyFeedback("Copy") }}>{room.roomId}</Button>
        </Tooltip>
    </div>
    <h2 className="text-center border-bottom px-2 mx-2 border-secondary py-2">Players</h2>
    <div className="my-4 overflow-auto d-flex flex-column playerBox">
        {
            players ?
                Object.keys(getPlayersSorted(players.players)).map((value, key) => <div key={key} className="d-flex my-2 flex-column justify-content-between col-12 align-items-center">
                    <Avatar {...stringAvatar(players.players[value].name)} />
                    <Typography fontSize={20} color={players.players[value].answer ? "green" : "white"}>{room.playerId === value ? "You" : players.players[value].name} - <span className="text-success">{players.players[value].score}</span></Typography>
                </div>)
                : <LoadingButton loading style={{ fontSize: "2em" }} />
        }
    </div>
    </>
}

export const PlayersSmall = ({copyFeedback,setCopyFeedback,players})=>{
    const room = useSelector(state => state.room)
    return <>
        <div className="mb-2 text-center d-sm-block d-block d-md-none">
            <Typography>Room ID</Typography>
            <Tooltip title={copyFeedback} arrow placement="right-start">
                <Button variant="outlined" className="w-auto overflow-hidden d-inline-block roomIdBtn" color="secondary" name="copy"  endIcon={<ContentCopyIcon />} onMouseDown={() => { copy(room.roomId); setCopyFeedback("Copied !!") }} onMouseLeave={() => { setCopyFeedback("Copy") }}>{room.roomId}</Button>
            </Tooltip>
        </div>
        <div className="d-flex flex-column col-12">
            <h2 className="text-center px-2 mx-2 py-2">Players</h2>
            <div className=" d-flex gap-4 p-4 border-top border-bottom justify-content-center overflow-auto border-secondary">
                {
                    players ?
                        Object.keys(getPlayersSorted(players.players)).map((value, key) =>
                            <div key={key} className="d-flex flex-column justify-content-between align-items-center">
                                <Avatar {...stringAvatar(players.players[value].name)} />
                                <Typography fontSize={20} color={players.players[value].answer ? "green" : "white"}>{room.playerId === value ? "You" : players.players[value].name} - <span className="text-success">{players.players[value].score}</span></Typography>
                            </div>)
                        : <LoadingButton loading style={{ fontSize: "2em" }} />

                }
            </div>
        </div>
    </>
}