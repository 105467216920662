import { Button } from "@mui/material"
import MusicPlayer from "./MusicPlayer"

const QuestionGuess = ({players,room,hashSong,songs,songSelected,randomChoice,isChoiceSelected,turn,inputHandler,backgroundMusic})=>{
    return players.players[room.playerId].turn ?
            (players.stage === 0 ?
                <div className="my-4">
                    <h4 className="chooseSong">Choose a Song</h4>
                    <div className="d-flex flex-wrap gap-4 justify-content-center">
                        {
                            Array.from(randomChoice).map((value, key) => <Button key={key} variant="outlined" size="medium" className="w-auto text-wrap" color="secondary" name="choiceBtn" song={value} disabled={isChoiceSelected} onClick={inputHandler} >{songs[value].name}</Button>)
                        }
                    </div>
                </div> :
                <div className="my-4">
                    <h4 className="chooseSong">Song Selected {songs[songSelected].name}</h4>
                    {
                        players && players.stage === 1 && <MusicPlayer song={backgroundMusic} background={true}  />
                    }
                </div>

            ) :
            (players.stage === 0 ?
                <div className="my-4">
                    <h5 className="text-warning">
                        {
                            `${players.players[turn].name} is Choosing Song`
                        }
                    </h5>
                </div> :
                <div className="my-4">
                    <h4 className="chooseSong">Guess The {players.level%2===1?"Song":"Singer"} : {players.level%2===1?hashSong(players, room.playerId, songs[songSelected].name):hashSong(players, room.playerId, songs[songSelected].singer)}</h4>
                    {players && players.stage === 1 && !players.players[room.playerId].turn && <MusicPlayer song={songs[songSelected].song} />}
                </div>

            )
}

export default QuestionGuess;