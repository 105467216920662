export const songs = [
    { name: 'Aye Khuda', song: 'Aye-Khuda',
        singer: "Salim Merchant"    
    },
    { name: 'Junooniyat', song: 'JUNOONIYAT',
        singer: "Pulkit Samrat"    
    },
    { name: 'Main Rang Sharbaton Ka', song: 'Main-Rang-Sharbaton-Ka',
        singer: "Chinmayi"    
    },
    { name: 'Raabta', song: 'Raabta',
        singer: "Arijit Singh"    
    },
    { name: 'Tere Vaaste', song: 'Tere-Vaaste',
        singer: "Varun Jain"    
    },
    { name: 'Mann Mera', song: 'Mann-Mera',
        singer: "Gajendra Verma"    
    },
    { name: 'SANAM RE', song: 'SANAM-RE',
        singer: "Arijit Singh"    
    },
    { name: 'Malang', song: 'Malang',
        singer: "Siddharth Mahadevan"    
    },
    { name: 'Kesariya', song: 'Kesariya',
        singer: "Arijit Singh"    
    },
    { name: 'Hawa Banke', song: 'Hawa-Banke',
        singer: "Darshan Raval"    
    },
    { name: 'Main Nikla Gaddi Leke', song: 'Main-Nikla-Gaddi-Leke',
        singer: "Aditya Narayan"    
    },
    { name: 'What Jhumka', song: 'What-Jhumka',
        singer: "Arijit Singh"    
    },
    { name: 'Dilbara', song: 'Dilbara',
        singer: "Sachet Tandon"    
    },
    { name: 'Humnava Mere', song: 'Humnava-Mere',
        singer: "Jubin Nautiyal"    
    },
    { name: 'Raat Bhar', song: 'Raat-Bhar',
        singer: "Shreya Ghoshal"    
    },
    {
      name: 'Mujhe To Teri Lat Lag Gayi',
      song: 'Mujhe-To-Teri-Lat-Lag-Gayi',
        singer: "Benny Dayal"    
    },
    {
      name: 'Pyaar Hota Kayi Baar Hai',
      song: 'Pyaar-Hota-Kayi-Baar-Hai',
        singer: "Arijit Singh"    
    },
    { name: 'Show Me The Thumka', song: 'Show-Me-The-Thumka',
        singer: "Shashwat Singh"    
    },
    { name: 'Thumkeshwari', song: 'Thumkeshwari',
        singer: "Rashmeet Kaur"    
    },
    { name: 'Sweety Tera Drama', song: 'Sweety-Tera-Drama',
        singer: "Pawni A Pandey"    
    },
    {
        name:'Malang',
        song:'Malang_TitleSong',
        singer: "Ved Sharma"    
    },
    {
        name:'Raabta',
        song:'RaabtaTitleSong',
        singer: "Arijit Singh"    
    },
    {
        name:'Tera Rastaa Chhodoon Na',
        song:'Tera-Rastaa-Chhodoon-Na',
        singer: "Amitabh Bhattacharya"    
    },
    {
        name:'Sadi Gali',
        song:'Sadi-Gali',
        singer: "Lehmber Hussainpuri"    
    },
    {
        name:'Ishq Wala Love',
        song:'Ishq-Wala-Love',
        singer: "Neeti Mohan"    
    },
    {
        name:'Tera Ghata',
        song:'Tera-Ghata',
        singer: "Gajendra Verma"    
    },
    {
        name:'O Saathi',
        song:'O-Saathi',
        singer: "Atif Aslam"    
    },
    {
        name:'Suno Na Sangemarmar',
        song:'Suno-Na-Sangemarmar',
        singer: "Arijit Singh"    
    },
    {
        name:'Kabhi Jo Badal Barse',
        song:'Kabhi-Jo-Badal-Barse',
        singer: "Arijit Singh"    
    },
    {
        name:'Mere Liye Tum Kaafi Ho',
        song:'Mere-Liye-Tum-Kaafi-Ho',
        singer: "Ayushmann Khurrana"    
    }
  ]

export const length = songs.length;

 export const isClose = (song1,song2)=>{
    let count=0;
    for(let i=0;i<Math.max(song1.length,song2.length);i++)
    {
        if(song1[i]!==song2[i])
        {
            count++;
        }
        if(count>1)
            return false;
    }
    if(count===1)
        return true;
    else
        return false
}
