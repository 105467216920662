import "./Footer.css"

const Footer = ()=>{
    return <div className="text-center fixed-bottom footer text-light d-flex flex-column">
        <p className="font-monospace col-12 mt-2 mb-0 copyrights">Copyright &copy;
            <a href="https://www.linkedin.com/in/harshilkhamar/" className="text-decoration-none footerLink"> Harshil Khamar</a>,
            <a href="https://www.linkedin.com/in/janvi-thakkar-496514194" className="text-decoration-none footerLink"> Janvi Thakkar</a>,
            <a href="https://www.linkedin.com/in/muskan0701" className="text-decoration-none footerLink"> Muskan Rawat</a>, 2024
            <br className="d-md-none d-sm-block"/><span className="d-md-inline d-none"> | </span>All Rights Reserved.
        </p>
        <p className="font-monospace col-12 mb-2 mt-0 copyrights">Developed By <a href="https://github.com/Harshil0512" className="text-decoration-none footerLink"> Harshil Khamar</a>
        </p>
    </div>
}

export default Footer;
