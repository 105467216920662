import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../Firebase/config";
import { length } from "./songs";
import axios from "axios";
import { endpoint } from "./endpoints";
const getSnapshot = (room,setTurn,setTimer,setChat,players,setIsChoiceSelected,setGuess,setRandomChoice,setPlayers,setSongSelected,updateInterval,setScoreBoardModalOpen)=>{
    const docRef = doc(db,'rooms',room.roomId)
    onSnapshot(docRef,(docs)=>{
            const interval_id = setInterval(function(){}, Number.MAX_SAFE_INTEGER);
            for (let i = 1; i < interval_id; i++) {
                clearInterval(i);
            }
            // if(docs.data().level===2)
            // {
            //     setScoreBoardModalOpen(true);
            // }
            // else
            // {
            //     setScoreBoardModalOpen(false)
            // }
            updateInterval(setInterval(async ()=>{
                let time = (await axios.post(endpoint+"/api/hh/time")).data.curTime
                time = (time-time%1000)/1000
                setTimer(time-((docs.data().startedAt-docs.data().startedAt%1000)/1000))
            },150))
            const shortedPlayers = Object.fromEntries(
                Object.entries(docs.data().players).sort(([,a],[,b]) => a.joinedAt-b.joinedAt)
            );
            setRandomChoice(docs.data().randomChoice);
            for(let i in shortedPlayers) {
                if(shortedPlayers[i].turn)
                {
                    setTurn(i);
                    break;
                }   
            }
            setIsChoiceSelected(docs.data().songSelected?true:false)
            if(docs.data().stage!==1)
            {
                setGuess("")
            }
            setChat(docs.data().chat)
            if(docs.data().stage===0 && players && players.stage===2)
            {
                setRandomChoice(new Set([Math.floor(Math.random()*players.level*length)%length,Math.floor(Math.random()*players.level*length)%length,Math.floor(Math.random()*players.level*length)%length]))
            }
            setPlayers({...docs.data(),players:shortedPlayers})
            setSongSelected(docs.data().songSelected)
    })
}

export default getSnapshot;