import { Avatar, Stack, Typography } from "@mui/material"
import "./Winners.css"
import { WinnersPodium } from "winner-podium";
import { useParams } from "react-router";
import getDocument from "../../functions/getDocument";
import { useEffect, useState } from "react";
import getPlayersSorted from "../../functions/getPlayersSorted";
import { stringAvatar } from "../../functions/avatar";
import { useSelector } from "react-redux";
import Footer from "../Footer/Footer";

const Winners = ()=>{
    const params = useParams();
    const room = useSelector(state=>state.room)
    const [players,setPlayers] = useState(undefined);
    useEffect(()=>{
        getDocument(params.id).then((room)=>{
            setPlayers(getPlayersSorted(room.data().players))
            if(!room.data().end)
            {
                window.location.href="/"
            }
        })
        .catch((e)=>{
            console.log(e);
            window.location.href="/"
        })
        setTimeout(()=>{
            window.location.href="/"
        },30000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return <div className="bg-container">
        <div className="col-12">
                <Stack gap={4} alignItems="center" className="pt-5 pb-2 col-md-5 col-sm-7 col-7 mx-auto"  >
                    <img src="/Assets/Images/logo1.png" className="img-fluid col-md-10 col-sm-12 col-12" alt="harmonichurdles" />
                    <img src="/Assets/Images/logo2.png" className="img-fluid col-md-4 col-sm-5 col-5" alt="harmonichurdles" />
                </Stack>
            </div>
            <div className="col-12 mt-5 text-center">
            {players && <div className="col-12"><WinnersPodium
            options={{
                container: {
                style: {
                    borderRadius: "1rem",
                },
                className: "shadow-lg",
                },
                podiumHeight: 70,
                podiumWidth: 110,
                backgroundColor: "rgb(99, 170, 222)",
                topViewHeight: 30,
                header: <h1 className="winner">Winners</h1>,
                winners: {
                rank1: (
                    Object.keys(players)[0]?
                    <div className="d-flex my-2 flex-column justify-content-between col-12 align-items-center">
                        <Avatar {...stringAvatar(Object.keys(players)[0])} />
                        <Typography fontSize={20} color={players[Object.keys(players)[0]].answer ? "green" : "white"}>{room.playerId === Object.keys(players)[0] ? "You" : players[Object.keys(players)[0]].name} - <span className="text-success">{players[Object.keys(players)[0]].score}</span></Typography>
                    </div>:
                    <></>
                ),
                rank2: (
                    Object.keys(players)[1]?
                    <div className="d-flex my-2 flex-column justify-content-between col-12 align-items-center">
                        <Avatar {...stringAvatar(Object.keys(players)[1])} />
                        <Typography fontSize={20} color={players[Object.keys(players)[1]].answer ? "green" : "white"}>{room.playerId === Object.keys(players)[1] ? "You" : players[Object.keys(players)[1]].name} - <span className="text-success">{players[Object.keys(players)[1]].score}</span></Typography>
                    </div>:
                    <></>
                ),
                rank3: (
                    Object.keys(players)[2] ?
                    <div className="d-flex my-2 flex-column justify-content-between col-12 align-items-center">
                        <Avatar {...stringAvatar(Object.keys(players)[2])} />
                        <Typography fontSize={20} color={players[Object.keys(players)[2]].answer ? "green" : "white"}>{room.playerId === Object.keys(players)[2] ? "You" : players[Object.keys(players)[2]].name} - <span className="text-success">{players[Object.keys(players)[2]].score}</span></Typography>
                    </div>:
                    <></>
                ),
                },
            }}
            />
            </div>
            }
            </div>
        <Footer />
    </div>
}

export default Winners