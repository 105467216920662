import { TextField } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./JoinRoom.css"
import { useEffect, useState } from "react";
import Stack from "@mui/joy/Stack";
import { LoadingButton } from "@mui/lab";
import { joinRoom } from "../../actions/room";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/config";
import Footer from "../Footer/Footer";

const JoinRoom = (req)=>{
    const params = useParams();
    useEffect(()=>{
        const docRef = doc(db,"rooms",params.id)
        getDoc(docRef).then((value)=>{
            if(!value.exists())
            {
                return <Navigate to="/" />
            }
        }).catch((e)=>{
            setHelperText("Internal Server Error");
            setError(true);
        })
    },[params])
    const USER_NAME = "userName";
    const JOIN_ROOM = "joinRoom"
    const [error,setError] = useState(false)
    const [helperText,setHelperText] = useState("")

    const [userName,setUserName] = useState("")
    const room = useSelector(state=>state.room)
    const loading = room.loading;

    const dispatch = useDispatch()
    if(room.status)
    {
        return <Navigate to="/lobby" />
    }
    const inputHandler = (e)=>{
        switch(e.target.name)
        {
            case USER_NAME:
                setUserName(e.target.value);
                setError(false);
                setHelperText("")
            break;
            case JOIN_ROOM:
                if(userName==="")
                {
                    setError(true)
                    setHelperText("Username is Required")
                }
                else
                {
                    dispatch(joinRoom(userName,params.id))
                }
            break;
            default:

        }
    }

    return <div className="bg-container">
        <div className="background container text-center">
            <Stack gap={4} alignItems="center" className="mb-5 pt-2"  >
                <img src="/Assets/Images/welcome.png" className="img-fluid w-50" alt="harmonichurdles" />
                <img src="/Assets/Images/logo1.png" className="img-fluid w-75" alt="harmonichurdles" />
                <img src="/Assets/Images/logo2.png" className="img-fluid w-25" alt="harmonichurdles" />
            </Stack>
            <div className="d-flex justify-content-center align-items-center flex-column">
                <img src="/Assets/Images/profile.png" className="img-fluid profileLogo mb-0" alt="harmonichurdles" />
                <TextField variant="filled" color="secondary" label="Enter Your Name" className="mb-4" value={userName} name={USER_NAME} error={error} helperText={helperText} onChange={inputHandler} required />
            </div>
            <div>
                <LoadingButton variant="contained" size="large" loading={loading} loadingPosition="end" name={JOIN_ROOM} onClick={inputHandler} color="secondary" endIcon={<ArrowForwardIcon />}>
                    Join
                </LoadingButton>
            </div>
        </div>
        <Footer />
    </div>
}

export default JoinRoom