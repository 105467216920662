const getPoints = (position,timeLeft,level)=>{
    switch(position)
    {
        case 1:
            return Math.floor(((timeLeft*20)/1000)+(level*20))
        case 2:
            return Math.floor(((timeLeft*16)/1000)+(level*20))
        case 3:
            return Math.floor(((timeLeft*14)/1000)+(level*20))
        case 4:
            return Math.floor(((timeLeft*10)/1000)+(level*20))
        case 5:
            return Math.floor(((timeLeft*8)/1000)+(level*20))
        case -1:
            return 0
        default:
            return Math.floor(((timeLeft*5)/1000)+(level*20))
    }
}

export default getPoints;