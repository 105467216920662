import {createStore, combineReducers, applyMiddleware} from "redux"
import {composeWithDevTools} from "redux-devtools-extension"
import thunk from "redux-thunk"
import roomReducer from "./Reducers/roomReducer";

const rootReducer = combineReducers({
    room : roomReducer
})

const middleware = [thunk];
const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(...middleware)))

export default store;