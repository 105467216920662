import { Route, Routes } from "react-router"
import Home from "./Pages/Home/Home"
import Join from "./Pages/Join/Join"
import CreateRoom from "./Pages/CreateRoom/CreateRoom"
import Lobby from "./Pages/Lobby/Lobby"
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute"
import JoinRoom from "./Pages/JoinRoom/JoinRoom"
import Game from "./Pages/Game/Game"
import Winners from "./Pages/Winners/Winners"

const App = ()=>{
    return <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/join" element={<Join />} />
        <Route path="/createroom" element={<CreateRoom />} />
        <Route path="/room/:id" element={<JoinRoom />} />
        <Route path="/game/:id" element={<ProtectedRoute />} >
            <Route path="/game/:id" element={<Game />} />
        </Route>
        <Route path="/lobby" element={<ProtectedRoute />} >
            <Route path="/lobby" element={<Lobby />} />
        </Route>
        <Route path="/winners/:id" element={<Winners />} ></Route>
    </Routes>
}

export default App
