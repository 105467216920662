import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom"
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import {load} from 'google-fonts-loader';

load({'Chilanka': ['400', '400i', '700', '700i']});
const root = createRoot(document.getElementById("root"))

root.render(<>
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
</>)