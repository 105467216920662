import { Avatar, Button, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../Firebase/config";
import {ArrowForward} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { gameBegin } from "../../actions/room";
import { Navigate } from "react-router";
import copy from 'copy-to-clipboard';
import Footer from "../Footer/Footer";
// import { ToastContainer, toast } from 'material-react-toastify';
// import 'material-react-toastify/dist/ReactToastify.css';
import "./Lobby.css"
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
const Lobby =  ()=>{
    const room = useSelector(state=>state.room)
    const docRef = doc(db,'rooms',room.roomId)
    const [players,setPlayers] = useState(undefined)
    const [copyFeedback,setCopyFeedback] = useState("Copy");
    const [open, setOpen] = useState(false);
    const loading = room.loading;
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    useEffect(()=>{
        onSnapshot(docRef,(docs)=>{
            setPlayers(docs.data())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    if(players)
    {
        if(players.gameStatus)
        {
            return <Navigate to={`/game/${room.roomId}`} />
        }
    }
    const begin = ()=>{
        if(Object.keys(players.players).length>1)
        {
            dispatch(gameBegin(room.roomId))
        }
        else
        {
            setOpen(true)
            // toast.error("Minimum 2 Players Required !!", {
            //     position: "bottom-center",
            //     theme: "colored",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     });
            // toast.error("Minimum 2 Players Required !!", {
            //     position: "bottom-center",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            //     });
        }
    }
    
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
            width: 100, 
            height: 100,
            fontSize:50
          },
          children: `${name[0]}${name[1]}`,
        };
      }
    
    return <div className="bg-container">
        <div className="background container text-center">
            <Stack gap={4} alignItems="center" className="mb-5 pt-2"  >
                <img src="/Assets/Images/welcome.png" className="img-fluid w-50" alt="harmonichurdles" />
                <img src="/Assets/Images/logo1.png" className="img-fluid w-75" alt="harmonichurdles" />
                <img src="/Assets/Images/logo2.png" className="img-fluid w-25" alt="harmonichurdles" />
            </Stack>
            <h5 className="display-6">Waiting For Players...</h5>
            <Tooltip title={copyFeedback} arrow placement="right-start">
                <Button variant="outlined" className="w-auto text-wrap" color="secondary" name="copy" endIcon={<ContentCopyIcon />} onMouseDown={()=>{copy(room.roomId);setCopyFeedback("Copied !!")}} onMouseLeave={()=>{setCopyFeedback("Copy")}}>{room.roomId}</Button>
            </Tooltip>
            <Stack gap={2} flexWrap="wrap" direction={"row"} className="my-4" alignItems="center" justifyContent="center">
                {
                    players?Object.keys(players.players).map((value,key)=><div key={key}>
                            <Avatar {...stringAvatar(players.players[value].name)}/>
                            <Typography fontSize={20} color="white">{players.players[value].name}</Typography>
                        </div>):<LoadingButton loading style={{fontSize:"2em"}} />
                }
            </Stack>
            {
                players?players.players[room.playerId] ? players.players[room.playerId].admin?
                <>
                    <LoadingButton variant="contained" loading={loading} name="begin" loadingPosition="end" onClick={begin} endIcon={<ArrowForward />}>Begin</LoadingButton>
                    <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{vertical:"bottom",horizontal:"center"}} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            Minimum 2 Players Required !!
                        </Alert>
                    </Snackbar>
                </>:"" :"":<LoadingButton loading />
            }
        </div>
        <Footer />
    </div>
}

export default Lobby