import { ANSWER_SUCCESS_FAIL, ANSWER_SUCCESS_REQUEST, ANSWER_SUCCESS_SUCCESS, CHAT_UPDATE_FAIL, CHAT_UPDATE_REQUEST, CHAT_UPDATE_SUCCESS, GAME_BEGIN_FAIL, GAME_BEGIN_REQUEST, GAME_BEGIN_SUCCESS, JOIN_ROOM_FAIL, JOIN_ROOM_REQUEST, JOIN_ROOM_SUCCESS, ROOM_CREATE_FAIL, ROOM_CREATE_REQUEST, ROOM_CREATE_SUCCESS, SONG_SELECT_FAIL, SONG_SELECT_REQUEST, SONG_SELECT_SUCCESS, UPDATE_SHUFFLE_FAIL, UPDATE_SHUFFLE_REQUEST, UPDATE_SHUFFLE_SUCCESS } from "../constants/roomConstants"

const initialState = {
    loading:false,
    status:false,
    gameStatus:false
}
const roomReducer = (state=initialState,action)=>{
    // console.log(action.type)
    switch(action.type)
    {
        case ROOM_CREATE_REQUEST:
        case JOIN_ROOM_REQUEST:
        case GAME_BEGIN_REQUEST:
        case SONG_SELECT_REQUEST:
        case UPDATE_SHUFFLE_REQUEST:
        case ANSWER_SUCCESS_REQUEST:
        case CHAT_UPDATE_REQUEST:
        {
            return {
                ...state,
                loading:true
            }
        }
        case ROOM_CREATE_SUCCESS:
        case JOIN_ROOM_SUCCESS:
        {
            return {
                ...state,
                loading:false,
                status:true,
                playerId:action.payload.playerId,
                roomId:action.payload.roomId
            }
        }
        case GAME_BEGIN_SUCCESS:
        {
            return {
                ...state,
                loading:false,
                gameStatus:true
            }
        }
        case SONG_SELECT_SUCCESS:
        case UPDATE_SHUFFLE_SUCCESS:
        case ANSWER_SUCCESS_SUCCESS:
        case CHAT_UPDATE_SUCCESS:
        case SONG_SELECT_FAIL:
        case UPDATE_SHUFFLE_FAIL:
        case ANSWER_SUCCESS_FAIL:
        case CHAT_UPDATE_FAIL:
        {
            return {
                ...state,
                loading:false
            }
        }
        case ROOM_CREATE_FAIL:
        case JOIN_ROOM_FAIL:
        {
            return {
                ...initialState
            }
        }
        case GAME_BEGIN_FAIL:
        {
            return {
                ...state,
                loading:false,
            }
        }
        default:
        {
            return {
                ...state
            }
        }
    }
}

export default roomReducer