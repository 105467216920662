import { Avatar, Typography } from "@mui/material"
import getPlayer from "../functions/getPlayer"
import { useSelector } from "react-redux"
import { stringAvatar } from "../functions/avatar"

const Turn = ({players})=>{
    const room = useSelector(state => state.room)
    return <>
    <h5 className="turn mx-4">Turn</h5>
    <div className="flex-column d-flex justify-content-center align-items-center gap-2 mb-2">
        {
            players ? <>
                <Avatar {...stringAvatar(getPlayer(players).name)} />
                <Typography fontSize={20} color="white">{players.players[room.playerId].name === getPlayer(players).name ? "You" : getPlayer(players).name}</Typography>
            </> : <></>
        }
    </div>
    </>
}

export default Turn