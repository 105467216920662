import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const MusicPlayer = ({song,background=false})=>{
    return <div className='col-10 col-sm-10 col-md-6 mx-auto shadow'>
    <AudioPlayer
    autoPlay
    className='mx-auto my-4 rounded-2'
    customAdditionalControls={[]}
    style={{ 
    backgroundColor: "#8EC5FC",
    backgroundImage: "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",
    }}
    src={`/Songs/${background?"background/":""}${song}.mp3`}
    onPlay={e => console.log("Play")}
    // other props here
  />
  </div>    
}

export default MusicPlayer;