// import { changeTurn, hold, songSelect, updateShuffle } from "../actions/room";
// import getPoints from "./getPoints";
// import getShuffle from "./getShuffle";
// import { songs } from "./songs";

const manipulateTime = (players,setScoreBoardModalOpen,setBackgroundMusic)=>{
    if(players)
        {
            if(players.stage===0)
            {
                setScoreBoardModalOpen(false);
                const temp = Math.floor(Math.random()*5)+1;
                setBackgroundMusic(temp)
                // let temp = {...players.players}
                // if(temp[room.playerId].turn)
                // {
                //     if(timer%11===0)
                //     {
                //         // dispatch(songSelect(song,getShuffle(players.level%2===1?songs[song].name:songs[song].singer),room.roomId))
                //     }
                // }
            }
            else if(players.stage===1)
            {
                setScoreBoardModalOpen(false);
                // let temp = players.players;
                // if(temp[room.playerId].turn)
                // {
                //     if(timer%10===0 && timer!==0)
                //     {
                //         let shuffle=Array.from(players.shuffle)
                //         shuffle.splice(Math.floor(Math.random()*shuffle.length),1)
                //         // dispatch(updateShuffle(shuffle,room.roomId))
                //     }
                //     if(timer%31===0)
                //     {
                //         Object.keys(temp).forEach((value,key)=>{
                //             // temp[value].points=getPoints(temp[value].position,temp[value].answeredAt-players.turnStartedAt,players.level);
                //         })
                //         // dispatch(hold(temp,room.roomId))
                //     }
                // }
            }
            else if(players.stage===2)
            {
                setScoreBoardModalOpen(true);
            //     let temp = {...players.players}
            //     if(timer%5===0)
            //     {
            //         Object.keys(temp).forEach((value,key)=>{
            //             temp[value].turn=false;
            //             temp[value].answer=false;
            //             temp[value].answeredAt=-1;
            //             temp[value].position=-1;
            //             temp[value].score+=temp[value].points;
            //             temp[value].points=0;
            //         })
            //         const length = Object.keys(temp).length;
            //         temp[Object.keys(temp)[(players.turn+1)%length]].turn=true;
            //         // dispatch(changeTurn(temp,(players.turn+1)%length,room.roomId))
            //     }
            }
        }
}

export default manipulateTime;
