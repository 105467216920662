import { TextField, Stack } from "@mui/material";
import {LoadingButton} from '@mui/lab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./Join.css"
import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/config";
import { Navigate } from "react-router";
import Footer from "../Footer/Footer";

const   Join = ()=>{

    // const [userName,setUserName] = useState("")
    const [roomCode,setRoomCode] = useState("")
    const [error,setError] = useState(false)
    const [helperText,setHelperText] = useState("")
    const [loading,setLoading] = useState(false);
    const [navigate,setNavigate] = useState(false);
    // const avatarRef = useRef()

    if(navigate)
    {
        return <Navigate to={`/room/${roomCode.trim()}`} />
    }


    const inputHandler = (e)=>{
        switch(e.target.name)
        {
            // case "userName":
            //     setUserName(e.target.value);
            // break;
            case "roomCode":
                setHelperText("")
                setError(false)
                setRoomCode(e.target.value);
            break;
            case "enterButton":
                setLoading(true);
                if(roomCode==="")
                {
                    setHelperText("Room Code is Required")
                    setError(true)
                    setLoading(false);
                }
                else
                {   
                    try
                    {
                        const docRef = doc(db,"rooms",roomCode.trim())
                        getDoc(docRef).then((value)=>{
                            if(value.exists())
                            {
                                setNavigate(true)
                            }
                            else
                            {
                                setHelperText("Incorret Room ID");
                                setError(true);
                                setLoading(false)
                            }
                        }).catch((e)=>{
                            setHelperText("Internal Server Error");
                            setError(true);
                        })
                    }
                    catch(e)
                    {
                        setHelperText("Incorret Room ID");
                        setError(true);
                        setLoading(false)
                    }
                }
            break;
            default:

        }
    }

    return <div className="bg-container">
        <div className="background container text-center">
            <Stack gap={4} alignItems="center" className="mb-5"  >
                <img src="/Assets/Images/welcome.png" className="img-fluid w-50" alt="harmonichurdles" />
                <img src="/Assets/Images/logo1.png" className="img-fluid w-75" alt="harmonichurdles" />
                <img src="/Assets/Images/logo2.png" className="img-fluid w-25" alt="harmonichurdles" />
            </Stack>
            {/* <Avatar
                alt="Avatar"
                src="/static/images/avatar/1.jpg"
                className="mx-auto"
                ref={avatarRef}
                sx={{ width: 200, height: 200 , fontSize:100}}
            /> */}
            <img src="/Assets/Images/room.avif" className="img-fluid" alt="room" />
            <Stack gap={2} alignItems="center" margin={2}>
                <TextField variant="filled" color="secondary" label="Enter Room Code" className="w-50" error={error} helperText={helperText} value={roomCode} name="roomCode" onChange={inputHandler} required/>
            </Stack>
            <div>
                <LoadingButton variant="contained" size="large" color="secondary" name="enterButton" onClick={inputHandler} loading={loading} loadingPosition="end" endIcon={<ArrowForwardIcon />}>
                    Enter
                </LoadingButton>
            </div>
        </div>
        <Footer />
    </div>
}

export default Join