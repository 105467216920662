import SendIcon from '@mui/icons-material/Send';

import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import "./Chat.css"
import { stringToColor } from '../functions/avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { isClose, songs } from '../functions/songs';
import { chatUpdate } from '../actions/room';
import axios from 'axios';
import { endpoint } from '../functions/endpoints';

const Chat = ({chat,guess,setChat,setGuess,players,inputHandler})=>{
    const room = useSelector(state => state.room)
    const chatBoxRef = useRef();
    const dispatch = useDispatch();
    return <div className="container col-11 col-md-6 col-sm-11 bg-light chatBox rounded-2 px-0">
    <h5 className="huddle py-2 mb-0 bg-secondary rounded-top text-light">Huddle</h5>
    <div className="chatBoxInner px-2" ref={chatBoxRef}>
        {
            chat && chat.map((value,key)=><div key={key} className={`d-flex ${value.name===room.playerId?"justify-content-end":"justify-content-start"}`}>
                {
                    value.name===room.playerId?
                    <p className="huddleText" style={{color:stringToColor(value.name)}}>
                        <span>
                            {players.songSelected && (players.level%2===1?value.message.toUpperCase()===songs[players.songSelected].name.toUpperCase():value.message.toUpperCase()===songs[players.songSelected].singer.toUpperCase()) && value.messagedAt>=players.turnStartedAt && !players.players[room.playerId].turn?<span className='text-success'>Correct ! </span>:""}
                            {players.songSelected && isClose(value.message.toUpperCase(),songs[players.songSelected].name.toUpperCase()) && value.messagedAt>=players.turnStartedAt && !players.players[room.playerId].turn && !players.players[room.playerId].answer?<span className='yellow'>Too Close ! </span>:""}
                            {value.message} - You
                        </span>
                    </p>:
                    players.songSelected && (players.level%2===1?value.message.toUpperCase()===songs[players.songSelected].name.toUpperCase():value.message.toUpperCase()===songs[players.songSelected].singer.toUpperCase()) && value.messagedAt>=players.turnStartedAt?
                    <></>:
                    <p className="huddleText" style={{color:stringToColor(value.name)}}>
                        <span>
                            {value.name} - {value.message}
                            {players.songSelected && isClose(value.message.toUpperCase(),songs[players.songSelected].name.toUpperCase()) && value.messagedAt>=players.turnStartedAt?<span className='yellow'> ! Too Close</span>:""}
                        </span>
                    </p>
                }
            </div>)
        }
    </div>
    <div className="d-flex">
        {/* <input type="text" id="guess" name="guess" value={guess} className="form-control border-0 outline-0 shadow-none" onChange={inputHandler} />
        <Button variant="outlined" className="w-auto text-wrap" color="secondary" name="submitBtn" onClick={inputHandler} >
            <SendIcon />
        </Button> */}
        <Paper
            component="form"
            className="col-12"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Guess Here"
        inputProps={{ 'aria-label': 'Guess Here' }}
        id="guess" 
        name="guess" 
        value={guess}
        onKeyDown={async (e)=>{if(e.key==="Enter"){
            e.preventDefault()
            if(guess.trim()!=="")
            {
                const time = (await axios.post(endpoint+"/api/hh/time")).data.curTime;
                dispatch(chatUpdate([...chat,{name:room.playerId,message:guess,messagedAt:time}],room,players,guess,room.roomId))
                setGuess("")
            }
        }}}
        onKeyUp={(e)=>{
            chatBoxRef.current.scrollTo(0,chatBoxRef.current.scrollHeight)
        }}
        onChange={inputHandler}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" name="submitBtn" className='submitBtn pointer-envets-none'onMouseUp={
            (e)=>{
                chatBoxRef.current.scrollTo(0,chatBoxRef.current.scrollHeight)
            }
        } 
        onMouseDown={inputHandler}>
        <SendIcon/>
      </IconButton>
    </Paper>
        
    </div>
</div>
}

export default Chat